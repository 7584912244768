@import '../../assets/definitions';

.admiter {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(#16191e, .99);
    z-index: 100;
    transition: all .125s linear;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h2 {
        margin: 0;
        font-size: 2.5rem;
    }

    h3 {
        margin: 0;
        border-bottom: 1px solid rgba(#fff, .1);
        padding-bottom: .75rem;
        font-size: 1rem;
        font-weight: 600;
    }

    p {
        color: #959aa2;
    }

    header {
        padding: 1rem;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid rgba(#fff, .1);
        box-shadow: 0 0 25px rgba(#000, .75);
        font-size: 1.5rem;

        div {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            margin-right: 1rem;
            background: rgba(rgb(150, 144, 144), .25);
            transition: all .125s linear;
            cursor: pointer;
            position: relative;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 14px;
                height: 2px;
                top: calc(50% - 1px);
                left: calc(50% - 7px);
                background: #fff;
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }

            &:hover {
                background: #1d95d7;
            }
        }
    }

    .admiter-menu {
        width: 250px;
        border-right: 1px solid rgba(#fff, .25);
        height: 100%;
        border-radius: 3px 0 0 3px;
        padding: 2rem;
    }

    .admiter-menu-item {
        padding: .5rem;
        position: relative;
        z-index: 0;
        cursor: pointer;
        transition: all .125s linear;

        &::before {
            content: "";
            position: absolute;
            top: 1px;
            left: 0;
            height: calc(100% - 2px);
            width: 0;
            background: #2d343d;
            z-index: -1;
            transition: all .25s linear;
            border-radius: 5px;
        }

        &.selected,
        &:hover {
            padding: .5rem 1rem;

            &::before {
                width: 100%;
            }
        }
    }

    .admiter-content {
        padding: 2rem;
        flex: 1;
        border-radius: 0 3px 3px 0;
        overflow: auto;
        position: relative;
    }

    .admiter-submitter {
        position: fixed;
        right: 1rem;
        top: 1.25rem;

        button {
            font-size: .6rem;
            padding: .5rem 1rem;
            cursor: pointer;
        }
    }

    &.open {
        transform: scale(1);
        opacity: 1;
    }

    &.close {
        transform: scale(0);
        opacity: 0;
    }

    .admiter-content-wrapper {
        flex: 1;
        padding: 0;
        display: flex;
        flex-direction: row;
        overflow: auto;
    }

    .admiter-sub-menu {
        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0 0 2rem 0;
            background: rgba(#fff, .02);
            border-radius: 5px;
            box-shadow: 1px 1px 3px rgba(#000,.25), inset 0 10px 20px rgba(#fff, .04);
            border-top: 1px solid rgba(#fff, .1);
            padding: .5rem;

            ul {
                margin: 0;
                padding: .5rem 0 0 0;
                background: none;
                box-shadow: none;
                border: 0;

                li {
                    padding: .25rem .5rem;
                    font-size: .7rem;
                    text-transform: initial;
                    background: darken(#38383c, 5%);

                    &:hover,
                    &.selected {
                        background: #98d71d !important;
                    }
                }
            }
        }

        li {
            padding: .5rem 1rem;
            text-transform: uppercase;
            background: rgba(#000, .15);
            box-shadow: inset 2px 5px 10px rgba(#000, .25);
            border-bottom: 1px solid rgba(#fff, .1);
            margin-right: .5rem;
            border-radius: 3px;
            font-size: .8rem;
            transition: all .25s linear;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &:hover,
            &.selected {
                box-shadow: inset 0 0 10px rgba(#fff, .25), inset 0 1px 0 rgba(#fff, .4);
                border-bottom: 1px solid rgba(#000, .5);
            }

            &:hover {
                box-shadow: inset 0 1px 0 rgba(#fff, .4);
            }

            &.selected {
                box-shadow: inset 0 0 10px rgba(#fff, .25), inset 0 1px 0 rgba(#fff, .4);
                cursor: default;
            }
        }
    }

    .theming-list {
        display: flex;
        flex-direction: row;
        padding: .5rem 0;

        & > div {
            border-radius: 17px;
            height: 34px;
            width: 34px;
            border: 4px solid transparent;
            margin: .25rem;
            cursor: pointer;
            transition: all .125s linear;
        }

        .selected {
            border: 4px solid rgba($color1, .85);
        }

        .theme1 {
            background: $theme1;
        }

        .theme2 {
            background: $theme2;
        }

        .theme3 {
            background: $theme3;
        }

        .theme4 {
            background: $theme4;
        }

        .theme5 {
            background: $theme5;
        }

        .theme6 {
            background: $theme6;
        }
    }

    .input-checkbox {
        border: 1px solid darken(#fff, 40%);

        &::before {
            background: $color2;
        }
    }

    .radio-list {
        margin: .5rem 0;
        border-radius: 10px;
        padding: .5rem .75rem;
        border: 2px solid rgba(94, 97, 112, .25);
        transition: all .125s linear;
        background: rgba(#000, .2);
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;

        & > span {
            margin: 0 1rem 0 0;
            display: inline-block;
        }
    }

    .admiter-bordered {
        border: 1px solid rgba(#fff, .05);
        border-radius: 5px;
        padding: 1rem;
        transition: all .125s linear;
        box-shadow: 0 0 10px rgba(#000, .25), inset 0 0 0 rgba(#1d95d7, 0);
        position: relative;
        overflow: hidden;
        transform: scale(1);
        transition: all .125s linear;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -50px;
            background: rgba(#fff, 0);
            width: 40px;
            height: 100%;
            transform: skewX(-10deg);
        }

        &::before {
            transition: all .42s linear;
        }

        &::after {
            transition: all .5s linear;
        }

        .admiter-bordered-header {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .8rem;
            transition: all .125s linear;
            position: relative;
            color: #1d95d7;

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 50%;
                height: 1px;
                background: rgba(#fff, .6);
                transition: all .25s linear;
                box-shadow: 0 0 10px 5px #1d95d7;
            }

            &::before {
                top: 0;
                left: -150%;
            }

            &::after {
                bottom: 0;
                left: 150%;
            }
        }

        &.clickable:hover {
            border: 1px solid rgba(#1d95d7, .8);
            box-shadow: 0 0 10px rgba(#000, 1), inset 0 0 15px rgba(#1d95d7, .4);
            transform: scale(0.95);
            animation: .75s linear 0.125s infinite alternate glow;

            &::before,
            &::after {
                background: rgba(#fff, .03);
                left: calc(100% + 50px)
            }

            .admiter-bordered-header {
                color: #fff;

                &::before {
                    left: 150%;
                }

                &::after {
                    left: -150%;
                }
            }
        }
    }
}

@keyframes glow {
    from {
        border: 1px solid rgba(#1d95d7, .8);
        box-shadow: 0 0 10px rgba(#000, 1), inset 0 0 15px rgba(#1d95d7, .4), 0 0 80px rgba(#1d95d7, 0.1);
    }
    to   {
        border: 1px solid rgba(#1d95d7, .1);
        box-shadow: 0 0 10px rgba(#000, 1), inset 0 0 10px rgba(#1d95d7, .3), 0 0 0 rgba(#1d95d7, 0);
    }
}