.admiter-mercato-filters {
    border: 1px solid rgba(#fff, .1);
    border-radius: 8px;
    padding: 0 .25rem .25rem .25rem;
}

.advertisement-textarea textarea{
    min-height: 24rem;
}

.advertisement-html {
    overflow: auto;
    max-height: 400px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        padding: 0;
        margin: 0 0 1rem 0;
        color: #fff;
        border: 0;
    }

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.3rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1.1rem;
    }

    h5 {
        font-size: 1rem;
    }

    a {
        color: orange;
    }

    hr {
        height: 1px;
        background: rgba(#fff, .25);
    }

    q,
    blockquote {
        margin: 0;
        padding: 1rem;
        border-radius: 5px;
        background-color: rgba(#fff, .01);
        border-left: 4px solid rgba(#fff, .1);
    }

    code {
        padding: 1px 4px;
        border-radius: 5px;
        border: 1px solid rgba(#000, .1);
        color: #c7254e;
        background-color: #f9f2f4;
        font-size: 90%;
    }

    pre {
        margin: 0;
        background-color: rgba(#000, .1);
        display: inline-block;
        padding: .25rem;
        border: 1px solid rgba(#000, .25);
        unicode-bidi: embed;
        font-family: monospace;
        border-radius: 5px;
        padding: .75rem 1rem;
        margin-bottom: 1rem;
        display: block;

        code {
            background: none;
            padding: 0;
            border: 0;
        }

        .language-javascript {

        }
    }

    p {
        color: #fff;
        margin-bottom: 1rem;
    }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        border-spacing: 0;
        border-collapse: collapse;
    }

    th {
        vertical-align: bottom;
        border-bottom: 2px solid rgba(#fff, .8);
        padding: .5rem;
        text-align: left;
    }

    td {
        vertical-align: top;
        border-top: 1px solid rgba(#fff, .3);
    }

    img {
        max-width: 100%;
    }
}