// Definition
$background1: #16191e;
$color1: #fff;
$color2: #1d95d7;
$color3: #252b33;
$color4: #000;
$color5: lighten(#2d343d, 10%);
$color6: #1e2329;
$color7: #ddd;
$color8: #21ca39;
$color9: rgb(197, 0, 0);
$color10: #ff6600;

$theme1: #d75a1d;
$theme2: #1d95d7;
$theme3: #98d71d;
$theme4: #d71d79;
$theme5: #a51dd7;
$theme6: #1dd77d;