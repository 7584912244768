@import '../../assets/definitions';

.ux-favorite {
    display: inline-block;

    svg {
        cursor: pointer;
        transition: all .125s linear;
        -webkit-transition: all .125s linear;
        -moz-transition: all .125s linear;
        -ms-transition: all .125s linear;
        -o-transition: all .125s linear;
        height: 24px;
    }

    .x-checkbox {
        path {
            fill: transparent;
            stroke: $color1;
            stroke-width: 40px;
        }
    }

    .x-checkbox-checked {
        path {
            fill: $color1;
            stroke: transparent;
            stroke-width: 40px;
        }
    }

}