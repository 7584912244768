.profile {
    height: 100%;

    .profile-avatar {
        image {
            width: 200px;
        }
    }

    .profile-circle-background {
        fill: none;
        stroke: #434d5a;
        stroke-width: 14px;
        opacity: 0.5;
    }

    .profile-circle-progress {
        fill: none;
        stroke-width: 14px;
        transform-origin: center;
        stroke: #1d95d7;
        transform: rotate(-215deg);
    }

    .profile-level {
        transform: translate3d(0px, 140px, 0) scale(.35);

        path {
            fill: #1d95d7;
        }
        text {
            fill: white;
            font-size: 100px
        }
    }
}