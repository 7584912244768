@import '../assets/definitions';

.team-page {
    .team-page-hero {
        border-radius: 10px;
        box-shadow: 3px 3px 10px rgba(#000, .75), inset 0 1px 0 rgba(#fff, .15);
        height: 300px;
        margin: 2rem 0;
        position: relative;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        overflow: hidden;

        h1 {
            margin: 0;
            padding: 0 1rem .5rem 1rem;
            font-weight: 400;
            position: absolute;
            bottom: 0;
            width: 100%;
            box-shadow: 0 0 50px rgba(#fff, .5), 0 0 25px rgba(#fff, .5), 0 0 5px rgba(#fff, .5);
            border-top: 1px solid rgba(#000, .75);

            img {
                vertical-align: middle;
            }
        }
    }

    .team-page-hero-experience {
        display: inline-block;
        width: 100%;
        height: 15px;
        background: rgba(#000, .33);
        border-radius: 7px;
        border-bottom: 1px solid rgba(#fff, .1);
        border-top: 1px solid rgba(#000, .15);

        & > div {
            height: 8px;
            width: 0;
            border-radius: 4px;
            margin: 2px 3px;
            position: relative;
        }
    }

    .team-roster-inactive,
    .team-roster {
        a {
            color: #fff;
        }
        .table-role {
            width: 50px;
            svg {
                width: 100%;
                path {
                    fill: #fff;
                }
            }
        }
        .table-player {
            flex: 1;
        }

        .table-date {
            width: 100px;
        }
    }

    .team-informations {
        .table-label {
            width: 90px;
        }
        .table-information {
            flex: 1;
        }
    }
}