.admiter-social-page {
    .social-field {
        .input-prefix {
            padding-right: 0 !important;
            color: darken(#fff, 50%);
        }
    }

    svg {
        height: 1.25rem;
        margin-right: .5rem;
        vertical-align: middle;
    }
}