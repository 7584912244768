.banner-chosen {
    border: 1px solid rgba(#fff, .1);
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    flex-direction: column;

    .banner-chosen-button {
        width: 100%;
        height: 100%;
        position: relative;
        flex: 1;

        img {
            width: 100%;
            height: 100%;
            z-index: 1;
            position: relative;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: rgba(#fff, .5);
            z-index: 0;
        }

        &::before {
            width: 60px;
            height: 1px;
            left: calc(50% - 30px);
            top: 50%
        }

        &::after {
            height: 60px;
            width: 1px;
            top: calc(50% - 30px);
            left: 50%
        }
    }

    .banner-chosen-cropper {
        position: relative;
        width: 100%;
        height: 100%;

        &::before {
            content: "loading ...";
            position: absolute;
            top: calc(50% - 35px);
            left: calc(50% - 35px);
        }
    }

    .chooser {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    button {
        border-radius: 0;
        cursor: pointer;

        &:disabled {
            background: #444;
            opacity: .25;
            cursor: default;
        }
    }
}