
.donation {
    text-align: left;
    background: #1d95d7;
    padding: .25rem 1rem;
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
    display: inline-block;
    position: relative;
    width: 130px;
    transition: all .125s linear;
    -webkit-transition: all .125s linear;
    -moz-transition: all .125s linear;
    -ms-transition: all .125s linear;
    -o-transition: all .125s linear;

    &.close {
        transition-delay: .5s;
    }

    &.disabled {
        background: #bbb;
        opacity: .35;
        .donation-header {
            cursor: default;
        }
    }
}

.donation-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    span:first-child {
        flex: 1;
    }

    svg {
        height: 24px;
        margin-left: .5rem;

        path {
            fill: #fff;
        }
    }
}

.donation-wrapper {
    position: absolute;
    max-height: 0;
    width: 200px;
    left: 0;
    top: calc(100% + .5rem);
    overflow: hidden;
    transition: all .125s linear .5s;
    -webkit-transition: all .125s linear .5s;
    -moz-transition: all .125s linear .5s;
    -ms-transition: all .125s linear .5s;
    -o-transition: all .125s linear .5s;
    z-index: 100;

    input {
        border: 0;
        padding: .25rem 1rem;
        width: 100%;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        text-align: right;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type=number] {
            -moz-appearance:textfield;
        }
    }

    .donation-content {
        width: 200px;
        overflow: hidden;
        background: #1d95d7;
        padding: 1rem;
        border-radius: .5rem;
        -webkit-border-radius: .5rem;
        -moz-border-radius: .5rem;
        -ms-border-radius: .5rem;
        -o-border-radius: .5rem;

        sup {
            font-size: .5rem;
        }
    }

    .donation-amounts {
        padding: .5rem 0;
    }

    .donation-amount {
        color: #fff;
        background: #21ca39;
        padding: .25rem .5rem;
        display: inline-block;
        margin: .25rem;
        font-size: .8rem;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        cursor: pointer;

        &.disabled {
            background: #bbb;
            opacity: .35;
        }
    }
}

.donation {
    &.open {
        width: 200px;

        .donation-wrapper {
            max-height: 200px;
        }
    }

    &.close {
        .donation-wrapper {
            transition-delay: 0s;
        }
    }
}