@import '../assets/definitions.scss';

.hero-list {
    display: flex;
    flex-direction: column;

    .hero-filters {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        padding: 0 .25rem;
        flex-wrap: wrap;
    }

    .hero-filter {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        color: #fff;
        margin: 0 4px 4px 0;
        border-radius: 5px;
        align-items: center;
        text-transform: uppercase;
        font-size: .6rem;
        padding: .25rem;
        line-height: 1rem;
        cursor: pointer;
        transition: all .125s linear;
        opacity: .25;
        min-width: 130px;

        img {
            height: 14px;
            margin-right: .25rem;
        }

        &.selected {
            opacity: 1;
        }
    }

    .heros {
        display: flex;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        margin-bottom: 1rem;
    }

    .hero {
        transition: all .125s linear;
        cursor: pointer;
        overflow: hidden;
        border-radius: 5px;
        height: 91px;
        width: 91px;
        margin: 2px 0 0 2px;
        border: 2px solid transparent;
        transform: scale(1);
        position: relative;

        span {
            position: absolute;
        }

        .hero-name {
            top: 5px;
            left: 5px;
            color: #fff;
            font-size: .6rem;
            text-shadow: 1px 1px 0 #000;
            text-transform: uppercase;
        }

        .hero-roles {
            bottom: 1px;
            left: 5px;
            display: block;
            img {
                width: 15px;
                filter: grayscale(100%) brightness(100%);
            }
        }

        &::before {
            z-index: 1;
            border-radius: 3px;
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            background: linear-gradient(180deg, rgba(#fff, .9) 0%, rgba(#fff,0) 15%, rgba(#fff,0) 66%, rgba(#fff, .5) 100%);
        }

        img {
            filter: grayscale(100%) brightness(33%);
            width: 100%;
            transition: all .125s linear;
        }

        &:hover {
            transform: scale(1.1);
            z-index: 1;
            img {
                filter: grayscale(60%) brightness(75%);
            }
        }

        &.selected {
            img {
                filter: grayscale(0) brightness(100%);
            }
        }
    }

    .hero-list-info {
        margin-bottom: 1rem;
        padding: 0 .5rem;
        display: flex;
        flex-direction: column;

        .hero-list-info-list {
            flex: 1;
            border: 1px solid rgba(#fff, .1);
            border-radius: 5px;
            padding: .5rem .5rem .25rem .5rem;
            font-size: .8rem;
        }

        .hero-list-info-list-default,
        .hero-list-info-list-stamp {
            display: inline-block;
            padding: .15rem .5rem;
            border-radius: 3px;
            margin-right: .25rem;
            margin-bottom: .25rem;
        }

        .hero-list-info-list-default {
            background: darken(#fff, 85%);
        }

        .hero-list-info-list-stamp {
            cursor: pointer;
        }

        .hero-list-info-input {
            margin-bottom: 1rem;
        }

        input {
            border-radius: 5px;
            border: 0;
            height: 2rem;
            padding: .5rem;
            width: 100%;
            background: rgba(#fff, .1);
            color: #fff;
            text-transform: uppercase;
            font-size: .6rem;
        }
    }
}