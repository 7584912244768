.admiter-structure-page {

    .table-structure-game {
        width: 70px;
        text-align: center;
        img {
            width: 24px;
        }
    }

    .table-structure-logo {
        width: 70px;
        text-align: center;
        img {
            width: 24px;
        }
    }

    .table-structure-name {
        flex: 1;
    }

    .table-structure-ladder {
        width: 85px;
        text-align: center;
    }

    .table-structure-player {
        width: 85px;
        text-align: center;
    }
}