.ux-toast-container {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 250px;
    max-width: calc(320px - 24px);
    padding: .25rem .75rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: 101;
}

.ux-toast-toaster {
    background: #ddd;
    color: #fff;
    padding: .75rem 1rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 1px 1px 4px rgba(0,0,0,.15);
    margin: .5rem 0;
    display: flex;
    flex-wrap: wrap;
    transition: all .25s linear;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.ux-toast-toaster-close {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
}

.ux-toast-message {
    flex: 1;
    text-shadow: 1px 1px 0px rgba(0,0,0, .25);
}

.ux-toast-close {
    cursor: pointer;
    position: relative;
    right: -10px;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

    &:after,
    &::before {
        content: "";
        position: absolute;
        width: .6rem;
        height: 2px;
        background: #fff;
    }

    &:after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }

    &::before {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
}
}

.ux-toast-error {
    background: red;
    color: #fff;
}

.ux-toast-success {
    background: 
    #94DA1F;
    color: #fff;
}

.ux-toast-warn {
    background: orange;
    color: #fff;
}