@import 'definitions';

body {
    background: $background1;
    color: $color1;
    overflow: hidden;
}

p + p {
    margin-top: 1rem;
}

.svg-tiny {
    width: 24px;
}

.svg-micro {
    width: 16px;
}

.cursor {
    cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.anim125 {
    transition: all .125s linear;
    -webkit-transition: all .125s linear;
    -moz-transition: all .125s linear;
    -ms-transition: all .125s linear;
    -o-transition: all .125s linear;
}

.anim250 {
    transition: all .25s linear;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
}

button {
    color: $color1;
    background: $color2;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 1px 1px 5px rgba($color4, .5);
    width: 100%;
    border: 0;
    padding: 1rem;
    font-weight: 600;

    &.green {
        background: $color8;
    }

    &.orange {
        background: $color10;
    }

    &.tiny {
        padding: .25rem;
    }

    &.small {
        padding: .5rem;
    }

    &.noupper {
        text-transform: none;
    }

    &.nobold {
        font-weight: normal;
    }

    svg {
        path {
            fill: #fff;
        }
    }
}

// MENU and Contact
.sidepanel-right,
.sidepanel-left {
    display: flex;
    flex-direction: column;
    background: $color3;
    width: 0;
    overflow: hidden;
    z-index: 1;
}

.sidepanel-right {
    padding-top: 1rem;

    &.open {
        width: 284px;
        box-shadow: 0 10px 10px rgba($color4, .85);
    }

    header {
        background: $color6;
        padding: .5rem 1rem;
        margin-bottom: .5rem;
    }
}


// MENU
.sidepanel-left {
    width: 60px;
    box-shadow: 0 0 5px rgba($color4, .5);

    path,
    rect,
    circle {
        fill: $color7;
        transition: all .125s linear;
        -webkit-transition: all .125s linear;
        -moz-transition: all .125s linear;
        -ms-transition: all .125s linear;
        -o-transition: all .125s linear;
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        padding-top: 1rem;

        .menu-separator {
            margin: 1rem;
            height: 1px;
            background-color: $color5;
            width: 60%;
        }

        .menu-entry {
            cursor: pointer;
            margin: .25rem 0;
            height: 44px;
            width: 44px;
            text-align: center;
            background: $color5;
            border-radius: 22px;
            -webkit-border-radius: 22px;
            -moz-border-radius: 22px;
            -ms-border-radius: 22px;
            -o-border-radius: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all .25s linear;
            -webkit-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -ms-transition: all .25s linear;
            -o-transition: all .25s linear;
            position: relative;

            img {
                width: 30px;
                border-radius: 50%;
                transition: all .25s linear;
                -webkit-transition: all .25s linear;
                -moz-transition: all .25s linear;
                -ms-transition: all .25s linear;
                -o-transition: all .25s linear;
            }

            &::before {
                content: "";
                position: absolute;
                background: $color2;
                top: 50%;
                width: 3px;
                left: -8px;
                height: 0;
                transition: all .25s linear;
                -webkit-transition: all .25s linear;
                -moz-transition: all .25s linear;
                -ms-transition: all .25s linear;
                -o-transition: all .25s linear;
                border-radius: 0 3px 3px 0;
                -webkit-border-radius: 0 3px 3px 0;
                -moz-border-radius: 0 3px 3px 0;
                -ms-border-radius: 0 3px 3px 0;
                -o-border-radius: 0 3px 3px 0;
            }
            &.selected,
            &:hover {
                path,
                rect,
                circle {
                    fill: $color1;
                }

                &::before {
                    top: 2px;
                    height: calc(100% - 4px);
                }

                background: $color2;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;

                img {
                    border-radius: 5px;
                }
            }
        }

        .menu-footer {
            background: $color6;
            align-self: flex-end;
            width: 100%;
            padding: .5rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: auto;

            .menu-entry {
                background: none;
                height: 36px;
                width: 36px;
            }
        }
    }
}

@media only screen and (max-width: 28em) {
    .content {
        order: 1;
        padding-bottom: 54px;
    }

    .el-coin-wrapper {
        display: none !important;
    }

    .topnav > ul > li {
        padding: 0 .5rem;
    }

    .sidepanel-right {
        order: 2;
        position: fixed;
        right: 0;
        height: calc(100% - 3.5rem);
        &.open {
            width: 100%;
        }
    }

    .sidepanel-left {
        order: 3;
        width: 100%;
        height: 54px;
        position: fixed;
        bottom: 0;
        left: 0;
        .menu {
            flex-direction: row;
            padding: 0;

            .menu-separator {
                display: none;
            }

            .menu-footer {
                margin-top: unset;
                margin-left: auto;
                width: auto;
                height: 100%;
            }

            .menu-entry {
                margin: 0 .25rem;
                cursor: pointer;

                &::before {
                    top: -5px !important;
                    height: 3px !important;
                    width: 0;
                    left: 50%;
                    border-radius: 0 0 3px 3px !important;
                }

                &:hover,
                &.selected {
                    &::before {
                        left: 2px;
                        width: calc(100% - 4px);
                    }
                }
            }
        }

    }
}

@keyframes logoOffline {
    0%   {opacity: 1; transform: scale(1);}
    100% {opacity: .5; transform: scale(.8)}
}

// top NAV
.topnav {
    background-color: $color6;
    box-shadow: 0 0 5px rgba($color4, .5);
    display: flex;
    flex-direction: row;
    height: 60px;
    z-index: 2;

    & > ul {
        height: 60px;
        flex: 1;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: flex-end;

        & > li {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0 1rem;
        }
    }

    svg {
        height: 24px;

        &.mright {
            margin-right: .5rem;
        }

        rect,
        path {
            fill: $color1;
        }
    }

    .chatswitcher {
        cursor: pointer;

        &.open {
            background: $color3;
        }
    }

    .menulogo {
        margin-right: auto;
        padding: 0;
    }

    .menu-icon {
        height: 100%;
        width:60px;
        background: $color2;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px rgba($color4, 1);
        position: relative;

        svg {
            width: 50px;
            height: 50px;
        }

        &.socket-offline {

            svg {
                animation-name: logoOffline;
                animation-duration: .5s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }

        .service {
            position: absolute;
            top: 0;
            width: 0;
            left: 100%;
            height: 100%;
            text-align: right;
            transition: all .125s linear;
            overflow: hidden;
            padding-top: 18px;
            white-space: nowrap;
            background: rgba(#fff, .05);

            &::before {
                content: "Service offline !";
                padding-right: 1rem;
            }
        }

        .service-offline {
            width: 140px;
        }
    }

    .friends-list {
        height: 36px;
    }

    .el-coin {
        height: 36px;
        text {
            fill: $color1;
        }
    }

    .el-coin-box {
        stroke-width: 0;
        fill: $background1;
    }

    .el-coin-value {
        font-size: 22px;
    }

    .el-coin-add {
        fill: $color2;
        cursor: pointer;
    }

    .el-coin-money {
        transform: scale(0.03) translate(5800px, 450px);
    }
}

.box {
    background: $color3;
    box-shadow: 0 0 5px rgba($color4, .5);
}

.box-header {
    background: $color6;
}

.nav-logged-wrapper {
    background: darken($color6, 3%);
    padding-right: 0 !important;
    box-shadow: inset 5px 0px 3px rgba(#000, .15);
    border-left: 1px solid rgba(#fff, .05);
}

.nav-logged-loading {
    padding-right: 1rem !important;
}

.nav-logged {
    display: flex;
    flex-direction: row;
    align-items: center;

    .nav-logged-avatar {
        margin-right: .75rem;
        .profile {
            width: 50px;
            height: auto;
        }
    }

    .nav-logged-name {
        font-size: .8rem;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .nav-logged-user {
        width: 100px;

    }

    .nav-logged-status {
        font-size: .7rem;
        position: relative;
        padding-left: .7rem;
        font-weight: 800;
        cursor: pointer;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: calc(50% - 3px);
            width: 8px;
            height: 8px;
            border-width: 2px;
            border-style: solid;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
        }

        &.online {
            color: $color8;
            &::before {
                border-color: $color8;
            }
        }

        &.offline {
            color: $color5;
            &::before {
                border-color: $color5;
            }
        }

        &.occuped {
            color: $color9;
            &::before {
                border-color: $color9;
            }
        }
    }

    .nav-logged-status-logout {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 0 0 .5rem;
        width: 50px;
        height: 60px;
        cursor: pointer;
        border-left: 1px solid rgba($color1, 0);
        transition: all .06s linear;
        -webkit-transition: all .06s linear;
        -moz-transition: all .06s linear;
        -ms-transition: all .06s linear;
        -o-transition: all .06s linear;

        & > div {
            overflow: hidden;
            display: block;
            position: absolute;
            margin: 0;
            right: -166px;
            top: 60px;
            padding: 0;
            width: 166px;
            background: $color5;
            height: auto;
            box-shadow: inset 0 5px 5px rgba($color4, .15), 0 1px 5px rgba($color4, .75);
            border-radius: 0 0 0 3px;
            -webkit-border-radius: 0 0 0 3px;
            -moz-border-radius: 0 0 0 3px;
            -ms-border-radius: 0 0 0 3px;
            -o-border-radius: 0 0 0 3px;
            transition: all .06s linear;
            -webkit-transition: all .06s linear;
            -moz-transition: all .06s linear;
            -ms-transition: all .06s linear;
            -o-transition: all .06s linear;
            z-index: 2;
        }

        ul {
            padding: .5rem;
            margin: 0;
            font-weight: 600;
        }

        li {
            display: block;
            padding: 0;
            font-size: .8rem;
            padding: .25rem 0 .25rem 1.25rem;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: .5rem;
                top: calc(50% - 4px);
                width: 8px;
                height: 8px;
                border-width: 2px;
                border-style: solid;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
            }

            &.online {
                color: $color8;
                &::before {
                    border-color: $color8;
                }
            }

            &.offline {
                color: lighten($color5, 25%);
                &::before {
                    border-color: lighten($color5, 25%);
                }
            }

            &.occuped {
                color: $color9;
                &::before {
                    border-color: $color9;
                }
            }

            &:hover {
                color: $color1;
                &::before {
                    border-color: $color1;
                }
            }
        }

        &.open {
            & > div {
                right: 0;
            }
        }
    }
}

.nav-register-opener,
.nav-login-opener {
    cursor: default;
    overflow: hidden;
    display: block;
    position: absolute;
    margin: 0;
    right: -266px;
    top: 60px;
    width: 266px;
    background: $color5;
    height: auto;
    box-shadow: inset 0 5px 5px rgba($color4, .15), 0 1px 5px rgba($color4, .75);
    border-radius: 0 0 0 3px;
    -webkit-border-radius: 0 0 0 3px;
    -moz-border-radius: 0 0 0 3px;
    -ms-border-radius: 0 0 0 3px;
    -o-border-radius: 0 0 0 3px;
    transition: all .06s linear;
    -webkit-transition: all .06s linear;
    -moz-transition: all .06s linear;
    -ms-transition: all .06s linear;
    -o-transition: all .06s linear;
    z-index: 1;
    padding: 1rem;

    input {
        border: 0;
        width: 100%;
        padding: .5rem;
        border-radius: 3px;
        background:#fff;
    }

    input + input {
        margin-top: 1rem;
    }

    button {
        cursor: pointer;
        padding: .5rem;
        margin-top: 1rem;

        &.warning {
            background-color: $color10;
        }
    }

    &.open {
        right: 0;
    }
}

.nav-register-opener-password {
    font-size: .6rem;
    text-transform: initial;
    margin: .25rem -.25rem 1rem -.25rem;

    span {
        background: #2d343d;
        padding: .15rem .25rem;
        display: inline-block;
        border-radius: 3px;
        margin: .15rem .25rem;
        transition: all .125s linear;
    }

    .success {
        background-color: #21ca39;
    }
}

h1 {
    font-weight: 800;
    padding-bottom: 2rem;
}

.ux-sidemenu-item {
    padding: .5rem 0;
}

.menu-title {
    text-transform: uppercase;
    font-weight: 400;
    border-left: 0 !important;
    cursor: default !important;
}

.menu-subtitle {
    border-left: 0 !important;
    cursor: default !important;
    text-transform: uppercase;
    font-weight: normal;
}

.menu-subentry {
    padding: .5rem 1.5rem .5rem 2.5rem !important;

    &.selected,
    &:hover {
        border-left: 5px solid greenyellow !important;
    }
}

.thema-root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

nav,
.box1-1,
.box2-1,
.box1-2,
.thema-root,
.bleft,
.menu-title,
.menu-subtitle{
    transition: all .25s linear;
    -webkit-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -ms-transition: all .25s linear;
    -o-transition: all .25s linear;
}

/*
.thema-dark {
    color: #ddd;
    background: rgba(#000, .85);

    h1 {
        border-bottom: 1px solid rgba(#fff, .25);
    }

    nav {
        border-bottom: 1px solid rgba(#fff, .2);
        color: #fff;
    }

    .bleft {
        border-left: 1px solid rgba(#fff, .2);

        path {
            fill: #fff;
        }
    }

    .url-site {
        color: #fff;
        text-shadow: 0 0 5px #fff;
    }

    aside {
        box-shadow: inset -1px 0 0 rgba(#fff, .08);
        svg {
            path {
                fill:rgba(#fff,.5);
            }

            &.selected,
            &:hover {
                path {
                    fill:rgba(#fff,.75);
                }
            }
        }
    }

    .menu-subentry {
        &.selected,
        &:hover {
            color: #fff;
        }
    }

    .menu-title {
        border-bottom: 1px solid rgba(#fff,.1);
        border-top: 1px solid rgba(#fff,.1);
        background: rgba(#fff, .05);
        color: #fff !important;
        text-shadow: 1px 1px 0 #000;
    }
    
    .menu-subtitle {
        color: white !important;
        border-bottom: 1px solid rgba(#fff, .1);
    }

    .ux-sidemenu {
        box-shadow: 0 0 5px #000;
        color: #fff;
    }

    .ux-sidemenu-sub-item {
        color: #888;
        &.selected,
        &:hover {
            color: #fff;
        }
    }

    .setting-panel-sidebar,
    .ux-sidemenu-sub-items {
        box-shadow:
            3px 3px 3px rgba(#000, .25),
            inset 0 0 50px rgba(#000, .75),
            inset -1px 0 0 rgba(#fff, .08);
        background: rgba(#000, .9);
    }

    .setting-panel-sidebar {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    .box {
        background: rgba(#000, .15);
        box-shadow: inset 0 0 5px rgba(#000, 1);
        border: 1px solid rgba(#fff, .08);

        header {
            background: rgba(#fff, .1);
            border-bottom: 1px solid rgba(#fff, .1);
            margin-bottom: .5rem;
        }
    }

    .ux-match + .ux-match {
        border-top: 1px solid rgba(#fff, .1);
    }

    .ux-match-icon {
        background: rgba(#fff, .05);
        box-shadow: 0 0 1rem rgba(#000, .75);
    }

    .ux-structure-icon {
        background: rgba(#fff, .1);
        box-shadow: 0 0 1rem .25rem rgba(#000, .75);
    }

    .ux-structure-header {
        box-shadow: inset 0 -1px 0 rgba(#fff, .15);
    }

    .ux-structure-title-header {
        color: #fff;
    }

    .ux-structure-icon-header {
        path {
            fill: #fff;
        }
    }

    .ux-structure-teams-header {
        background: rgba(#fff, .1);
    }

    .ux-team + .ux-team {
        border-top: 1px solid rgba(#fff, .1);
    }

    .inline-edit {
        color: #fff;
    }

    .ux-structure-description {
        border-bottom: 1px solid rgba(#fff, .25);
        box-shadow: inset 0 0 15px rgba(#000, .5), 0 0 1px rgba(#fff, .15);
    }

    .actions {
        box-shadow: 0 0 1px rgba(#fff, .2);
        margin-bottom: 1rem;
    }
}

.thema-light {
    color: #444;
    background: rgba(#fff, .85);

    nav {
        border-bottom: 1px solid rgba(#000, .2);
        color: #000;
    }

    .bleft {
        border-left: 1px solid rgba(#000, .2);

        path {
            fill: #666;
        }
    }

    .url-site {
        color: #fff;
        text-shadow: 1px 1px 3px rgba(#000, .5);
    }

    aside {
        box-shadow: inset -1px 0 0 rgba(#000, .08);
        svg {
            path {
                fill:rgba(#000,.5);
            }

            &.selected,
            &:hover {
                path {
                    fill:rgba(#000,.75);
                }
            }
        }
    }

    .menu-subentry {

        &.selected,
        &:hover {
            color: #000;
        }
    }

    .menu-title {
        border-bottom: 1px solid rgba(#000,.1);
        border-top: 1px solid rgba(#000,.1);
        background: rgba(#000, .05);
        color: #555 !important;
        text-shadow: 1px 1px 0 rgba(#fff, .5);
    }

    .menu-subtitle {
        color: #555 !important;
        border-bottom: 1px solid rgba(#000, .1);
    }

    .ux-sidemenu {
        box-shadow: 0 0 5px #fff;
        color: #000;
    }

    .ux-sidemenu-sub-item {
        color: #888;
        &.selected,
        &:hover {
            color: #666;
        }
    }

    .setting-panel-sidebar,
    .ux-sidemenu-sub-items {
        box-shadow:
            3px 3px 3px rgba(#000, .05),
            inset 0 0 50px rgba(#fff, .75),
            inset -1px 0 0 rgba(#000, .08);
        background: rgba(#fff, .9);
    }

    .setting-panel-sidebar {
        svg {
            path {
                fill: rgba(#000, .75);
            }
        }
    }

    .box {
        background: rgba(#fff, .15);
        box-shadow: inset 0 0 5px rgba(#fff, 1);
        border: 1px solid rgba(#000, .1);

        header {
            background: rgba(#fff, .35);
            border-bottom: 1px solid rgba(#fff, .35);
            margin-bottom: .5rem;
        }
    }

    .ux-match + .ux-match {
        border-top: 1px solid rgba(#000, .1);
    }

    .ux-match-icon {
        background: rgba(#000, .05);
        box-shadow: 0 0 1rem rgba(#fff, .75);
    }

    .ux-structure-icon {
        background: rgba(#000, .1);
        box-shadow: 0 0 1rem .25rem rgba(#fff, .25);
    }

    .ux-structure-teams-header {
        background: rgba(#fff, .3);
    }

    .ux-structure-icon-header {
        path {
            fill: #444;
        }
    }

    .ux-structure-title-header {
        color: #444;
    }

    .ux-team + .ux-team {
        border-top: 1px solid rgba(#000, .1);
    }

    .inline-edit {
        color: #000;
    }

    .ux-structure-description {
        border-top: 1px solid rgba(#fff, .75);
        box-shadow:
            inset 0 15px 10px rgba(#fff, .25),
            inset 0 0 15px rgba(#fff, .75),
            0 0 1px rgba(#000, .25);
    }
}
*/

.ux-match-win {
    color: #0072ce;
}

.ux-match-egal {
    color: gray;
}

main {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;

    nav {
        display: flex;
        flex-direction: row;
        height: 60px;

        & > ul {
            height: 60px;
            flex: 1;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            list-style: none;
            justify-content: flex-end;

            & > li {
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: row;
                padding: 0 1rem;

                &.start {
                    margin-right: auto;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        svg {
            height: 24px;

            &.mright {
                margin-right: .5rem;
            }
        }
    }

    .content {
        flex: 1;
        overflow: auto;
    }
}

/*
.box {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: auto;
    margin-bottom: 1rem;

    header {
        text-transform: uppercase;
        padding: 1rem;
        font-size: .9rem;
    }
}
*/

.rank-copper {
    color: #b76952;
}

.rank-silver {
    color: #a6a6a8;
}

.rank-gold {
    color: #efe9af
}

.rank-platinium {
    color: #d8d8d8
}

.rank-diamond {
    color: #b9c5db;
}

.rank-master {
    color: #ecbc67;
}

.rank-grandmaster {
    color: #fbe399;
}

.rank-top500 {
    color: #a1add7;
}

[class*="-rounded"][class*="rank-"] {
    padding: .1rem .5rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #444;
    font-size: .8rem;
    box-shadow: 1px 1px 2px rgba(#000, .25);
}

.rank-copper-rounded {
    background: #b76952;
}

.rank-silver-rounded {
    background: #a6a6a8;
}

.rank-gold-rounded {
    background: #efe9af
}

.rank-platinium-rounded {
    background: #d8d8d8
}

.rank-diamond-rounded {
    background: #b9c5db;
}

.rank-master-rounded {
    background: #ecbc67;
}

.rank-grandmaster-rounded {
    background: #fbe399;
}

.rank-top500-rounded {
    background: #a1add7;
}

.inline-edit {
    background: none;
    border: none;
    border-bottom: 1px solid grey;
    width: 100%;
}

.setting-panel {
    position: relative;

    .setting-panel-sidebar {
        overflow: hidden;
        width: 0;
        position: absolute;
        bottom: -.75rem;
        left: 54px;
        text-align: left;
        transition: all .125s linear;
        -webkit-transition: all .125s linear;
        -moz-transition: all .125s linear;
        -ms-transition: all .125s linear;
        -o-transition: all .125s linear;
        white-space: nowrap;
        border-radius: 0 5px 5px 0;
        -webkit-border-radius: 0 5px 5px 0;
        -moz-border-radius: 0 5px 5px 0;
        -ms-border-radius: 0 5px 5px 0;
        -o-border-radius: 0 5px 5px 0;
        font-size: .8rem;

        ul {
            list-style: none;
            padding: .5rem 1rem;
            margin: 0;
            text-transform: uppercase;

            li {
                margin: .5rem 0;
            }
        }

        svg {
            width: 18px;
            margin-right: .5rem;
            vertical-align: middle;
            position: relative;
            top: -.15rem;
        }
    }

    .setting-panel-open {
        width: 250px;
    }
}

.actions {
    padding: .5rem;

    svg {
        height: 24px;
    }
}


.background-stellar {
    background: url(../assets/background-stars.jpg) no-repeat 0 0;
    min-height: 400px;
}

.list-filters {

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        transition: all .125s linear;

        & > li {
            text-transform: uppercase;
            padding: 1rem;
            position: relative;
            transition: all .125s linear;
        }

        li li {
            font-size: .6rem;
            padding: .25rem 1rem .25rem 1.75rem;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                position: absolute;
            }

            &::before {
                border: 1px solid $color1;
                width: 12px;
                height: 12px;
                left: .75rem;
                top: calc(50% - 6px);
            }

            &::after {
                width: 8px;
                height: 8px;
                background: $color1;
                left: calc(.75rem + 2px);
                top: calc(50% - 4px);
                transform: scale(0);
                transition: all .125s linear;
            }

            &.checked {
                &::after {
                    transform: scale(1);
                }
            }

            &:first-child {
                margin-top: .5rem;
            }

            &:last-child {
                margin-bottom: .5rem;
            }
        }

        ul {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            top: 100%;
            background: darken($color2, 10%);
            width: 150px;
            overflow: hidden;
            max-height: 0;
            box-shadow: 0 4px 5px rgba(#000, .5);
            border-radius: 0 0 3px 3px;
            z-index: 1;
        }

        & > li:hover {
            background: darken($color2, 10%);

            & > ul {
                max-height: 500px;
                box-shadow: 0 4px 5px rgba(#000, .5);
            }
        }
    }

    li {
        display: inline-block;
        font-size: .8rem;
        cursor: pointer;
    }
}

$themes:(
    1: $theme1,
    2: $theme2,
    3: $theme3,
    4: $theme4,
    5: $theme5,
    6: $theme6,
);

@for $i from 1 through 6 {
    .theme#{$i} {

        .admiter h2,
        .admiter .sentence-label span,
        .admiter .admiter-bordered-header,
        .mercato-listing a,
        .ladder-info-content .ladder-info-datas .ladder-info-datas-team,
        .sentence-label span {
            color: map-get($themes, $i);
        }

        .admiter header div:hover,
        .admiter .admiter-menu-item.selected::before,
        .admiter .admiter-sub-menu li.selected,
        .admiter .admiter-sub-menu li:hover,
        .admiter .sentence-values ul,
        .sentence-label span
        .team-page-hero-experience > div,
        .ladder-info-content .stamp,
        .game-nav,
        .sidepanel-left .menu .menu-entry::before,
        .sidepanel-left .menu .menu-entry.selected,
        .sidepanel-left .menu .menu-entry:hover,
        .topnav .menu-icon,
        .game-list .game-list-filter,
        .sentence-values ul {
            background: map-get($themes, $i);
        }

        .nav-logged-status-logout path,
        .profile .profile-level path,
        .topnav .el-coin-add {
            fill: map-get($themes, $i);
        }

        .gamecard {
            &:hover {
                box-shadow: 0 0 20px 5px rgba(map-get($themes, $i), .5);
                border: 1px solid rgba(map-get($themes, $i), .75);

                .gamecard-wrapper {
                    &::before {
                        box-shadow: inset 0 0 3px rgba(map-get($themes, $i), .75);
                    }
                }
            }
        }

        .game-list .game-list-filter ul ul,
        .game-list .game-list-filter ul > li:hover,
        .list-filters ul ul,
        .list-filters ul > li:hover {
            background: darken(map-get($themes, $i), 10%);
        }

        .hero-list {

            .hero {
                &::before {
                    box-shadow: inset 0 0 0 map-get($themes, $i);
                }

                &:hover {
                    border: 2px solid map-get($themes, $i);
                    box-shadow: 0 0 15px map-get($themes, $i);
                }

                &.selected {
                    border: 2px solid map-get($themes, $i);
                }
            }

            .hero-list-info-list-stamp {
                background: map-get($themes, $i);
            }
        }

    }
}