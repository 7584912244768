.sentence {

    .sentence-filter {
        float: left;
        border: 1px solid rgba(#fff, .1);
        padding: .5rem;
        margin-left: .5rem;
        margin-bottom: .5rem;
        border-radius: 5px;
    }

    .sentence-filters {
        &::after {
            content: "";
            display: table;
            clear: both;
        }
    }

    .sentence-label {
        span {
            position: relative;
            display: inline-block;
            min-width: 50px;
            border-bottom: 1px solid rgba(#fff, 0.1);
            cursor: pointer;
            padding-bottom: .25rem;
            padding-right: .75rem;

            &::after {
                content: "";
                position: absolute;
                border: 4px solid transparent;
                border-top-color: transparent;
                border-top-style: solid;
                border-top-width: 4px;
                border-top: 4px solid #fff;
                right: 0;
                top: calc(50% - 2px);
            }
        }
    }

    .sentence-filter-optional {
        padding: 1rem 0;
        min-height: 4rem;
    }

    .sentence-filter-optionnal-item {
        border: 1px solid #fff;
        font-size: .7rem;
        padding: .25rem;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 3px;
        margin-left: .5rem;
        display: inline-block;
        opacity: 1;
        transition: all .125s linear;

        &.active {
            opacity: .1;
        }
    }

    .sentence-values {
        position: relative;

        ul {
            display: inline-block;
            margin: 0;
            padding: 0;
            list-style-type: none;
            position: absolute;
            left: -.5rem;
            top: calc(100% + 10px);
            color: #fff;
            font-size: .8rem;
            max-height: 0;
            overflow: auto;
            min-width: 150px;
            max-width: 300px;
            border-radius: 5px;
            transition: all .125s linear;
            z-index: 1;
        }

        li {
            padding: .25rem .5rem;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .open {
        ul {
            max-height:200px;
        }
    }

    .multiple {
        li {
            padding-left: 1.5rem;
            position: relative;
            vertical-align: middle;

            &::before,
            &::after {
                content: "";
                position: absolute;
            }

            &::before {
                width: 12px;
                height: 12px;
                border: 1px solid #fff;
                left: 8px;
                top: calc(50% - 6px);
            }

            &::after {
                width: 8px;
                height: 8px;
                background: #fff;
                left: 10px;
                top: calc(50% - 4px);
                transform: scale(0);
                transition: all .125s linear;
            }
        }

        .selected {
            &::after {
                transform: scale(1);
            }
        }
    }
}