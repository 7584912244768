@import '../../assets/definitions';

.ux-plusmoins {
    display: inline-block;

    svg {
        cursor: pointer;
        transition: all .125s linear;
        -webkit-transition: all .125s linear;
        -moz-transition: all .125s linear;
        -ms-transition: all .125s linear;
        -o-transition: all .125s linear;
        height: 24px;

        rect,
        path {
            fill: $color1;
        }
    }

}