.input-field {
    border: 0;
    background: transparent;
    display: block;
    flex: 1;
}

.input {
    display: flex;
    align-items: baseline;

    input[type=number] {
        -moz-appearance: textfield;
        text-align: right;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[text-align=center] {
        text-align: center;
    }

    input[text-align=right] {
        text-align: right;
    }

    input[text-align=left] {
        text-align: left;
    }
}

.input-suffix {
    padding-left: 1rem;
}

.input-prefix {
    padding-right: 1rem;
}

.input-no-padding {
    .input-suffix {
        padding-left: 0;
    }

    .input-prefix {
        padding-right: 0;
    }
}


.input-checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #fff;
    position: relative;
    display: inline-block;
    position: relative;
    top: 2px;

    &::before {
        content: "";
        position: absolute;
        transition: all .125s linear;
        transform: scale(0);
        width: 8px;
        height: 8px;
        background: #fff;
        left: 2px;
        top: 2px;
    }

    &.checked {
        &::before {
            transform: scale(1);
        }
    }
}
