.ux-member-list {
    height: 400px;
    overflow: hidden;

    .ux-member-list-wrapper {
        width: 200%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        transition: all .125s linear;
    }

    .ux-member-list-details {
        height: 100%;
        flex: 1;
        .ux-member-list-wrapper-scroller {
            height: calc(100% - 3rem);
            overflow: auto;
        }
    }

    .ux-member-list-table-name {
        flex: 1;
    }

    .ux-member-list-table-role {
        width: 80px;
        text-align: center;

        svg {
            cursor: pointer;
            width: 18px;
            path {
                fill: #fff;
            }
        }
    }

    .ux-member-list-table-actions {
        width: 85px;
    }

    .ux-member-list-setting {
        flex: 1;
        height: 400px;
        overflow: auto;
    }

    .ux-member-list-setting-role {
        padding: 1rem;

        ul {
            list-style: none;
            padding-left: 0;
        }

        svg {
            width: 18px;
            vertical-align: middle;
            margin-right: .5rem;
            path {
                fill: transparent;
                stroke: #fff;
                stroke-width: 4px;
                transition: all .125s linear;
            }

            &.selected {
                path {
                    fill: #fff;
                    stroke-width: 0;
                }
            }
        }

        li {
            padding: .5rem;
            cursor: pointer;
            color: darken(#fff, 50%);
            transition: all .125s linear;

            + li {
                border-top: 1px solid rgba(#fff, .15);
            }

            &:hover,
            &.selected {
                color: #fff;

                path {
                    fill: #fff;
                    stroke-width: 0;
                }
            }
        }
    }

    .ux-member-list-setting-show {
        transform: translateX(-50%)
    }

    .ux-member-list-setting-role-other {
        display: flex;
        flex-direction: row;

        .input {
            flex: 4;
            border-radius: 10px 0 0 10px;
        }

        button {
            font-size: .6rem;
            flex: 1;
            padding: 0;
            border-radius: 0 5px 5px 0;
            cursor: pointer;
        }
    }

    .ux-member-list-setting-closer {
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        vertical-align: middle;
        margin-right: .5rem;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            top: calc(50% - 6px);
            left: calc(50% - 6px);
            transform: rotate(45deg);
        }
    }
}