@import '../../assets/definitions';

.admiter-achievement-page {

    .achievement-xp-bar-wrapper {
        width: 100%;
        background: $color3;
        height: 10px;
        border-radius: 10px;
    }

    .achievement-xp-bar {
        background: $color2;
        height: 10px;
        border-radius: 10px;
        box-shadow: 0 0 3px rgba($color2, 1);
    }

    .achievement-xp {
        margin-bottom: .5rem
    }

}