.ux-invite-code {
    display: flex;
    flex-direction: row;
    background: #111317;    
    margin: .5rem 0;
    border-radius: 10px;
    overflow: hidden;
    cursor: default;

    .ux-invite-code-value {
        flex: 1;
        padding: .5rem 1rem;
    }

    .ux-invite-code-refresh {
        background: lighten(#111317, 5%);
        width: 2rem;
        cursor: pointer;
        margin-left: 1px;
        text-align: center;

        svg {
            margin: 8px 0 0 2px;
            height: 18px;
            path {
                fill: #fff;
            }
        }
    }

    .ux-invite-code-copy {
        background: lighten(#111317, 5%);
        width: 2rem;
        cursor: pointer;
        border-radius: 10px 0 0 10px;
        text-align: center;

        svg {
            margin: 8px 0 0 2px;
            height: 18px;
            path {
                fill: #fff;
            }
        }
    }
}