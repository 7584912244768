.admiter-game-page {
    .icon-steam-logo {
        width: 100px;
        vertical-align: middle;
        margin-right: 1rem;
    }

    .x-checkbox {
        cursor: pointer;
    }

    .admiter-game-page-chooser {
        display: flex;
        flex-direction: column;
        height: 100%;

        button {
            margin-bottom: 1rem;
        }
    }

    .admiter-game-page-chooser-input {
        padding: 1rem;

        input {
            border-radius: 5px;
            border: 0;
            height: 2rem;
            padding: .5rem;
            width: 100%;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
            text-transform: uppercase;
            font-size: .6rem;
        }
    }

    .admiter-game-page-chooser-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        overflow: auto;
        margin-bottom: 1rem;
        padding: 1.5rem;
    }

    .admiter-game-page-chooser-game {
        width: 200px;
        margin-right: 1rem;
    }
}