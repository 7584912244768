html,
body,
body > div,
main {
    height: 100vh;
    margin: 0;
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none !important;
    text-decoration: none;
}

body {
    text-rendering: optimizeLegibility;
    scrollbar-width: thin;
    scrollbar-color:#1e2329 #16191e;
}

p {
    margin: 0;
}

.upper {
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.size08 {
    font-size: .8rem;
}

.size10 {
    font-size: 1rem;
}

.size12 {
    font-size: 1.2rem;
}

.size14 {
    font-size: 1.4rem;
}

.size16 {
    font-size: 1.6rem;
}

.size18 {
    font-size: 1.8rem;
}

.size20 {
    font-size: 2rem;
}

.color-blue {
    color: #2691b7;
}

.color-orange {
    color: #f49237;
}

.color-darkgrey {
    color: #5a5a5a;
}

.color-green {
    color: #90c139;
}

.color-pink {
    color: #e70078;
}

.shadow-text-black {
    text-shadow: 1px 1px 0 #000;
}

.align-top {
    align-self: flex-start;
}

.align-center {
    align-self: center;
}

.align-bottom {
    align-self: flex-end;
}

.margin-auto {
    margin-right: auto;
    margin-left: auto;
}

.spacer10 {
    margin-bottom: 1rem !important;
}

.spacer15 {
    margin-bottom: 1.5rem;
}

.spacer20 {
    margin-bottom: 2rem;
}

.spacer30 {
    margin-bottom: 3rem;
}

.push {
    flex: 1;
}

.no-margin {
    margin: 0 !important;
}

.pointer {
    cursor: pointer;
}

hr {
    border: 0;

    &.space10 {
        height: 1rem;
    }

    &.space20 {
        height: 2rem;
    }

    &.space30 {
        height: 3rem;
    }

    &.space40 {
        height: 4rem;
    }

    &.space50 {
        height: 5rem;
    }

    &.space60 {
        height: 6rem;
    }
}

/* flex */

[class*="-flex-"] {
    display: flex;
}

.-flex-row {
    flex-direction: row;
}

.flex1 {
    flex: 1;
}

.full-height {
    height: 100%;
}

/* form */
label {
    display: block;
    margin: .5rem 0 1rem 0;
    cursor: pointer;
    font-size: .9rem;

    .input {
        margin-top: .5rem;
    }
}

.input {
    border: 2px solid rgba(94, 97, 112, .25);
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    color:#ebeef2;
    padding: 0 .5rem;
    width: 100%;
    position: relative;
    background: rgba(#000, .2);
    margin: 0;
    line-height: 2rem;
    transition: all .25s linear;

    &.input-modified {
        border-color: #f49237;
    }

    &:hover,
    &.input-focused {
        border-color: #1d95d7;
    }

    &.input-error {
        border-color: red;
        box-shadow: 0 0 5px red;
    }

    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    textarea,
    input {
        color:#ebeef2;
        height: 2rem;
    }

    textarea {
        padding: .5rem .25rem;
        min-height: 8rem;
        resize: none;

        &.horizontal {
            resize: horizontal;
        }

        &.vertical {
            resize: vertical;
        }
    }
}

input[type=email],
input[type=password],
input[type=text] {

    &.field-error {
        box-shadow: 0 0 10px red;
    }
}

.overflow-mover {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.info-box {
    border: 1px solid rgba(#fff, .1);
    padding: 0 1rem;
    font-size: .8rem;
    border-radius: 3px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: .75rem 0;

        & + li {
            border-top: 1px solid rgba(#fff, .1);
        }
    }
}