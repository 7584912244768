@import '../assets/definitions';

.player-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 200px;

    h1 {
        padding: 0;
        margin: 0;
    }
}

.player-page {

    h1 {
        padding: 0 0 .5rem 3rem;
        position: relative;
        z-index: 0;
        color: $color1;

        &::after,
        &::before {
            content: "";
            position: absolute;
            width: calc(100% - 2rem);
            height: 100%;
            z-index: -1;
            background: $color3;
        }

        &::before {
            transform: skew(-35deg);
            -webkit-transform: skew(-35deg);
            -moz-transform: skew(-35deg);
            -ms-transform: skew(-35deg);
            -o-transform: skew(-35deg);
        }

        &::after {
            left: 0;
            transform: skew(35deg);
            -webkit-transform: skew(35deg);
            -moz-transform: skew(35deg);
            -ms-transform: skew(35deg);
            -o-transform: skew(35deg);
        }
    }

    .favoris {
        padding: 0;
        width: 38px;
        height: 38px;
        display: inline-block;
        margin: 0 .5rem;
        border-radius: 24px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        -ms-border-radius: 24px;
        -o-border-radius: 24px;
        background: #e70078;

        svg {
            vertical-align: middle;
            height: 18px;
        }

        path {
            stroke: $color1;
            stroke-width: 40px;
            fill: transparent;
            transition: all .125s linear;
            -webkit-transition: all .125s linear;
            -moz-transition: all .125s linear;
            -ms-transition: all .125s linear;
            -o-transition: all .125s linear;
        }

        &.checked {
            path {
                fill: $color1;
            }
        }
    }

    .stream {
        overflow: hidden;
        box-shadow: 0 0 10px #000;
        border: 1px solid rgba(#000, .75);
        border-top: 1px solid rgba(#fff, .15);
    }

}