.ux-match {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        margin: 0;
    }
}

.ux-match-team2 {
    text-align: right;
}

.ux-match-team1,
.ux-match-team2 {
    flex: 1;
}

.ux-match-result,
.ux-match-team1,
.ux-match-team2 {
    padding: .5rem 1rem;
}

.ux-match-icon {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    img {
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
}

.ux-match-small {
    font-size: .8rem;

    p {
        display: inline;
    }

    .ux-match-icon {
        max-height: 24px;
        max-width: 24px;
        padding: .1rem;
        display: inline-block;
        vertical-align: middle;
    }

    .ux-match-team1 {
        .ux-match-icon {
            margin-right: .5rem;
        }
    }

    .ux-match-team2 {
        .ux-match-icon {
            margin-left: .5rem;
        }
    }
}

.ux-match-big {
    font-size: 1.5rem;
    padding: 1rem 0;

    .ux-match-icon {
        max-height: 256px;
        max-width: 256px;
        padding: 1.5rem;
        margin-bottom: 1rem;
        display: inline-block;
    }

    .ux-match-team1,
    .ux-match-team2 {
        text-align: center;
    }

    .ux-match-result {
        font-size: 4.5rem;
        display: flex;
        align-items: center;
        font-weight: 800;
        margin-top: -3.5rem;
    }
}