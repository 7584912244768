.ux-table-row {
    display: flex;
    flex-direction: row;
    font-size: .75rem;
    width: 100%;
    align-items: center;
    border-top: 1px solid rgba(#fff, .1);

    & > div {
        padding: .75rem 1rem;
        
    }    
}

.ux-table-header {
    display: flex;
    flex-direction: row;
    font-size: .75rem;
    box-shadow: inset 0 -1px 0 rgba(#000, .35);
    width: 100%;
    text-transform: uppercase;
    align-items: center;

    & > div {
        padding: 1rem;        
    }  
}
