@import '../assets/definitions';

.game-list {

    h1 {
        padding: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 1.8rem;
    }

    .game-list-hero-available {
        color: lighten($color6, 50%);
    }

    .game-list-wrapper {
        background: url( ../assets/background-gamelist.jpg) no-repeat top right;
        padding-bottom: 3rem;
        overflow: auto;
        min-height: 500px;
    }

    .game-list-hero {
        min-height: 180px;
        background: url( ../assets/fond-gamelist-hero.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    .game-list-filter {
        background: $color2;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            transition: all .125s linear;

            & > li {
                text-transform: uppercase;
                padding: 1rem;
                position: relative;
                transition: all .125s linear;
            }

            li li {
                font-size: .6rem;
                padding: .25rem 1rem .25rem 1.75rem;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                }

                &::before {
                    border: 1px solid $color1;
                    width: 12px;
                    height: 12px;
                    left: .75rem;
                    top: calc(50% - 6px);
                }

                &::after {
                    width: 8px;
                    height: 8px;
                    background: $color1;
                    left: calc(.75rem + 2px);
                    top: calc(50% - 4px);
                    transform: scale(0);
                    transition: all .125s linear;
                }

                &.checked {
                    &::after {
                        transform: scale(1);
                    }
                }

                &:first-child {
                    margin-top: .5rem;
                }

                &:last-child {
                    margin-bottom: .5rem;
                }
            }

            ul {
                position: absolute;
                display: flex;
                flex-direction: column;
                left: 0;
                top: 100%;
                background: darken($color2, 10%);
                width: 150px;
                overflow: hidden;
                max-height: 0;
                box-shadow: 0 4px 5px rgba(#000, .5);
                border-radius: 0 0 3px 3px;
                z-index: 1;
            }

            & > li:hover {
                background: darken($color2, 10%);

                & > ul {
                    max-height: 500px;
                    box-shadow: 0 4px 5px rgba(#000, .5);
                }
            }
        }

        li {
            display: inline-block;
            font-size: .8rem;
            cursor: pointer;
        }
    }
}

.ladder-list {
    .table-rank {
        width: 70px;
        text-align: center;
    }

    .table-team {
        flex: 1;

        img {
            height: 34px;
            vertical-align: middle;
            margin-right: 1rem;
        }

        a {
            color: #fff;
        }
    }

    .table-rating {
        width: 100px;
        text-align: center;
    }

    .table-games {
        width: 80px;
        text-align: center;
    }

    .table-wins {
        width: 80px;
        text-align: center;
    }

    .table-loses {
        width: 80px;
        text-align: center;
    }

    .table-level {
        width: 80px;
        text-align: center;
    }
}

.ladder-info {
    margin-right: 1rem;
    border-top: 1px solid rgba(#fff, .15);
    border-left: 1px solid transparent;
    border-bottom: 1px solid rgba(#000, .5);
    border-right: 1px solid transparent;
}

.ladder-list {
    flex: 1
}

.ladder-wrapper {
    display: flex;
    flex-direction: row;
    margin: .5rem 0;
}

.ladder-info-content {
    padding: .25rem 1rem 0 1rem;
    display: flex;
    flex-direction: row;

    .ladder-info-icon {
        margin-right: .5rem;
    }

    .ladder-info-title-wrapper {
        margin-bottom: .5rem;
        flex: 1;
    }

    .ladder-info-title {
        font-size: 1.2rem;
        text-transform: uppercase;
        flex: 1;
        align-self: center;
        border-bottom: 1px solid #fff;
        padding-bottom: .15rem;
        margin-bottom: .25rem;
    }

    .ladder-info-season {
        display: flex;
        flex-direction: row;

        .ladder-info-season-date {
            font-size: .7rem;
            color: darken(#fff, 50%);
            flex: 1;
        }
    }

    .stamp {
        display: inline-block;
        background: $color2;
        color: #fff;
        padding: .15rem .5rem;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: .7rem;
    }

    .ladder-info-datas {
        display: flex;
        flex-direction: row;
        font-size: .8rem;
        padding: 1rem 0;
        align-items: center;

        .ladder-info-datas-team {
            color: $color2;
        }

        .ladder-info-datas-more {
            margin-left: auto;
            display: flex;
            flex-direction: row;

            & > div {
                margin: 0 .25rem;
            }

            svg {
                height: 18px;
            }
        }
    }
}

.background-stellar {
    padding: 2rem 0;
    overflow: auto;
    flex: 1;
}

@-webkit-keyframes slidein1 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -110px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }
        @keyframes slidein1 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -110px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }

@-webkit-keyframes slidein2 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -130px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }
        @keyframes slidein2 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -130px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }

@-webkit-keyframes slidein3 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -160px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }
        @keyframes slidein3 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -160px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }

@-webkit-keyframes slidein4 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -200px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }
        @keyframes slidein4 { from { right:-300px;box-shadow: inset 0 0 30px rgba(255,255,255,.15); } to { right: -200px;box-shadow: inset 0 0 30px rgba(255,255,255,.05); }  }

.hero-animation {
    position: relative;

    .achild {
        position: absolute;
        height: 100%;
        background: rgba(#fff, .2);
        transform: skewX(30deg);
        width: 240px;
        right: -300px;
        top: 0;
        box-shadow: inset 0 0 30px rgba(255,255,255,.05);

        &.child1 {
            animation: .125s linear 1s 1 forwards slidein1;
        }

        &.child2 {
            animation: .125s linear 1.25s 1 forwards slidein2;
        }

        &.child3 {
            animation: .125s linear 1.5s 1 forwards slidein3;
        }

        &.child4 {
            animation: .08s linear 1.75s 1 forwards slidein4;
        }
    }
}