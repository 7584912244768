.ux-modal-wrapper {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0, .9);
    top: 0;
    left: 0;
    overflow: auto;
    align-items: center;
    justify-content: center;
    transition: all .125s linear;
    -webkit-transition: all .125s linear;
    -moz-transition: all .125s linear;
    -ms-transition: all .125s linear;
    -o-transition: all .125s linear;
    z-index: 1000;
}

.ux-modal-closable {
    cursor: pointer;
}

.ux-modal {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 100%;
    cursor: default;
    transition: all .125s linear;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.ux-modal-content {
    flex: 1;
    overflow: auto;
}

.ux-modal-full {
    padding: 0
}

.ux-modal-header {
    padding: .75rem;
    border-bottom: 1px solid #ddd;
    text-transform: uppercase;
    font-size: .8rem;
}

.ux-modal-close {
    background: rgba(0,0,0,0);
    cursor: default;
    pointer-events: none;

    .ux-modal {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
}

@media only screen and (min-width: 360px){
    .ux-modal {
        width: 360px;
    }
}

@media only screen and (min-width: 640px){
    .ux-modal {
        width: 640px;
    }
}

@media only screen and (min-width: 720px){
    .ux-modal {
        width: 720px;
    }
}