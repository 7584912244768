.gamecard {
    margin: 2rem 0 0 0;
    background: rgba(#1e2329, .9);
    overflow: hidden;
    border-radius: 5px;
    transition: all .125s linear;
    transform: scale(1);
    box-shadow: 0 0 5px rgba(#000, .5);
    border-top: 1px solid rgba(#fff, .15);
    border-left: 1px solid transparent;
    border-bottom: 1px solid rgba(#000, .5);
    border-right: 1px solid transparent;

    .gamecard-wrapper {
        position: relative;

        &::before {
            transition: all .125s linear;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &.gamecard-not-selected {
        filter: grayscale(1);

        .gamecard-image {
            img {
                opacity: .05;
            }
        }
    }

    &.gamecard-selected,
    &:hover {
        filter: grayscale(0);
        box-shadow: 0 0 20px 5px rgba(#1d95d7, .5);
        border: 1px solid rgba(#1d95d7, .75);
        transform: scale(1.04);

        .gamecard-wrapper {
            &::before {
                box-shadow: inset 0 0 3px rgba(#1d95d7, .75);
            }
        }

        .gamecard-image {
            img {
                opacity: 1;
            }
        }
    }

    .gamecard-image {
        img {
            width: 100%;
            display: block;
            transition: all .125s linear;
        }
    }

    .gamecard-content {
        padding: .5rem 1rem 1rem;
    }

    .gamecard-name {
        font-size: .9rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        color: #fff;
    }

    .gamecard-title-wrapper {
        display: flex;
        flex-direction: row;
    }

    .gamecard-icon {
        margin-right: .5rem;
    }

    .gamecard-title {
        overflow: hidden;
        flex: 1;
    }

    .gamecard-type {
        text-transform: uppercase;
        font-size: .6rem;
        display: block;
        color: lighten(#1e2329, 40%);
    }

    .gamecard-bar,
    .gamecard-platform {
        text-align: right;
        display: block;
        margin-top: .12rem;

        svg {
            margin: 0 .25rem;
            height: 12px;

            polygon,
            path {
                fill: #fff;
            }
        }
    }

    .gamecard-platform {
        flex: 1;
        .ps4 {
            g {
                transform: translateY(-150px);
            }
        }

        .pc {
            g {
                transform: translateY(-50px);
            }
        }
    }

    &.gamecard-tiny {
        margin: 1rem 0 0 0;

        .gamecard-title {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: .25rem .75rem .5rem .75rem;
        }

        .gamecard-name {
            text-shadow: 1px 1px 1px #000, 1px 1px 1px #000;
            hyphens: auto;
            white-space: normal;
        }
    }

    .gamecard-bar {
        display: flex;
        flex-direction: row;
    }
}