.ux-structure {

    .ux-structure-header {
        display: flex;
        flex-direction: row;
        font-size: 1.5rem;
        padding: .5rem .75rem;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;

        .ux-structure-title-header{
            flex: 1;
            font-weight: 800;
        }

        .ux-structure-icon-header {
            svg {
                height: 24px;
                margin-right: .5rem;
                vertical-align: middle;
                position: relative;
                top: -.15rem;
            }
        }

        .ux-structure-icon {
            max-height: 42px;
            max-width: 42px;
            padding: .25rem;
        }
    }

    .ux-structure-icon {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        img {
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .ux-structure-teams-header {
        font-weight: 800;
        font-size: .8rem;
        display: flex;
        flex-direction: row;

        .ux-team-name {
            padding: .5rem 1rem;
            flex: 1;
        }
    
        .ux-team-number,
        .ux-team-cote {
            padding: .5rem;
        }

        .ux-team-number {
            width: 100px;
            text-align: center;
        }
    
        .ux-team-cote {
            width: 150px;
            text-align: center;
        }
    }

    .ux-structure-description {
        margin: 1rem;
        padding: .5rem .75rem;
        height: 200px;
        overflow: auto;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        font-size: .85rem;
    }
}