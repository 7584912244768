@import '../assets/definitions';

.mercato-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url(../assets/mercato-hero.jpg);
    min-height: 180px;
    padding: 0 2rem;

    h1 {
        padding: 0;
        text-transform: uppercase;
    }
}

.mercato-filters {
    background: $color3;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .question-sentence-fragment-wrapper,
    .question-sentence-line {
        float: left;
        border: 1px solid rgba(#fff, .1);
        padding: .5rem;
        margin-left: .5rem;
        margin-bottom: .5rem;
        border-radius: 5px;
    }

    .question-sentence-fragment-wrapper {
        margin-left: 0;
    }
}

.mercato-listing {

    .table-logo {
        width: 40px;
        img {
            border-radius: 20px;
            box-shadow: 0 0 5px rgba(#000, .75);
        }
    }

    .table-who {
        flex: 1;
    }

    .table-rating {
        width: 150px;
    }

    .table-view {
        width: 50px;

        svg {
            cursor: pointer;
        }

        path {
            fill: #fff;
        }
    }

    .advertisement-content {
        min-height: 50vh;
        padding: 1rem 2rem;
        overflow: auto;
    }
}