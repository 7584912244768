.box {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;

    & + .box {
        margin-top: 2rem;
    }
}

.box-header {
    font-weight: 600;
    font-size: .9rem;
    padding: 1rem;
}

.box-content {
    padding: 1rem 1.5rem;
}